/**
 * Script to add common features between every view of the website
 * and to add the temporary alert display mechanism.
 * @author See list of contributors {@link https://gitlab.com/sia-insa-lyon/portailva/-/graphs/master?ref_type=heads}
 * @license AGPL-3.0-or-later
 */
import $ from 'jquery';
import 'bootstrap';
import '../../css/main.css';

$(() => {
    $('[data-toggle="tooltip"]').tooltip();
    $('[data-toggle="popover"]').popover();

    $('button[data-alert]').on('click', (event) => {
        const $root = $('#temporary-alert-div');

        /** Function to properly remove existing toasts and mount new toasts displayed to the user
         *
         * @param {function} mountToasts Callback called after hiding, and before showing, toasts
         *
         */
        const transitionToNewToast = (mountToasts) => (...props) => {
            $root.hide();
            $root.empty();
            mountToasts(...props);
            $root.show();
            $root.find('.toast').toast('show');
        };
        event.preventDefault();

        $.ajax({
            url: $(event.currentTarget).attr('data-alert'),
            method: 'GET',
            beforeSend: transitionToNewToast(() => {
                $root.append(`
                    <div class="position-fixed bottom-0 end-0 p-3" style="z-index: 5;">
                        <div class="toast align-items-center text-white bg-dark border-0" role="alert" aria-live="assertive" aria-atomic="true" data-autohide="false">
                            <div class="d-flex">
                                <div class="toast-body">
                                    <div class="spinner-border spinner-border-sm" role="status"><span class="sr-only">Chargement ...</span></div>
                                    Chargement
                                </div>
                            </div>
                        </div>
                    </div>
                `);
            })
        }).done(transitionToNewToast((data) => {
            let toasts = '';
            data.forEach((a) => {
                toasts += `<div class="toast align-items-center text-${a.type !== 'warning' ? 'white' : 'dark'} bg-${a.type} border-0" role="alert" aria-live="assertive" aria-atomic="true" data-autohide="false">
                        <div class="d-flex">
                            <div class="toast-body">${a.content}<br/><small>Alerte valide du ${(new Date(a.begins_at).toLocaleDateString('fr-FR'))} au ${(new Date(a.ends_at).toLocaleString('fr-FR'))}.</small></div>
                            <button type="button" class="close mt-auto mb-auto ml-auto mr-3" data-dismiss="toast" style="filter: invert(1) grayscale(100%) brightness(200%);">&times;</button>
                        </div>
                    </div>`;
            });
            $root.append(`<div class="position-fixed bottom-0 end-0 p-3" style="z-index: 5;">${toasts}</div>`);
            $('.icon-badge > .badge').remove();
        })).fail(transitionToNewToast((err) => {
            $root.append(`
                <div class="position-fixed bottom-0 end-0 p-3" style="z-index: 5;">
                    <div class="toast align-items-center text-white bg-danger border-0" role="alert" aria-live="assertive" aria-atomic="true" data-autohide="false">
                        <div class="d-flex">
                            <div class="toast-body">${(err.responseJSON && err.responseJSON.detail) ? err.responseJSON.detail : 'Une erreur inattendue est survenue lors de la récupération de vos alertes.'}</div>
                            <button type="button" class="close mt-auto mb-auto ml-auto mr-3" data-dismiss="toast" style="filter: invert(1) grayscale(100%) brightness(200%);">&times;</button>
                        </div>
                    </div>
                </div>
            `);
        }));
        return false;
    });

    // Display the "go back to the top" button and add the associated effect
    const animDuration = 600;
    $('.scroll-top').on('click', (event) => {
        event.preventDefault();
        $('html, body').animate({ scrollTop: 0 }, animDuration);
        return false;
    });

    $(window).on('scroll', () => {
        if ($(this).scrollTop() > (Math.round(window.innerHeight / 100)) * 150) {
            $('.scroll-top').fadeIn(animDuration);
        } else {
            $('.scroll-top').fadeOut(animDuration);
        }
    });
});
